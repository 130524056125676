import { debounceTime, filter } from 'rxjs/operators';
import { get } from 'lodash-es';
import { Subscription } from 'rxjs';
import { FieldGroup, FieldGroupType, flattenFieldGroupDefinitions } from './../../../core/store/field-groups/field-groups.model';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { WidgetTypeFieldConfiguration } from './../../../core/store/widget-types/widget-types.model';
import { Component, Input, OnDestroy, QueryList, ViewChildren, OnChanges, SimpleChanges } from '@angular/core';
import { CustomFormConstructorService } from '../form-constructor/custom-form-constructor.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { typecastAbstractToFormControl } from '../../../shared/shared-functions';
import { ModalsService } from '../../../shared/modals/modals.service';
import { FieldBuilderComponent } from '../field-builder/field-builder.component';
import { generateHelpTooltip } from '../field-builder/info-tooltip.text';

/**
 * This component is used in FieldBuilderComponent template to handle custom fields of
 * `group` type. It basically pulls the CFG from the api and instantiates the form fragment
 * as defined by the CFG.
 *
 * Note: CFGs cannot be nested at this time, there is no legal way to create that config
 * as well as additional initialization code being needed
 */
@Component({
  selector: 'gd-field-group-builder',
  templateUrl: './field-group-builder.component.html',
  styleUrls: ['./field-group-builder.component.scss']
})
export class FieldGroupBuilderComponent implements OnChanges, OnDestroy {

  @Input() fieldConfig: WidgetTypeFieldConfiguration;
  @Input() fieldControlGroup: UntypedFormGroup;
  @Input() isEditingMode: boolean;
  @Input() hasActionPermission: boolean;
  @Input() width = 70;
  @Input() usage;
  @Input() activeLocale;
  @Input() readOnlyMode = false;

  infoTooltip = '';

  @ViewChildren(FieldBuilderComponent) customFields!: QueryList<FieldBuilderComponent>;

  /**
   * This is the custom field group domain object, once fetched from the API
   *
   * TODO rename this to just formControl, as it can be a form array as well
   */
  customFieldGroup: FieldGroup = null;

  /**
   * list of all field definitions in one field group, for all steps
   */
  flatFieldDefinitions: WidgetTypeFieldConfiguration[] = [];

  componentSubscriptions = new Subscription();

  loadingError = null;
  availableLabelSpace: number = 215;

  // bound to allow usage of enum in template
  FieldGroupType = FieldGroupType;
  typecastAbstractToFormControl = typecastAbstractToFormControl;

  // holds info which panel is expanded
  loadedPanelIds = [];
  formFragmentTitleKey = null;
  isExternalDataSource = false;

  get formArray() {
    return this.fieldControlGroup as unknown as UntypedFormArray;
  }

  constructor(
    private formConstructorService: CustomFormConstructorService,
    private modalsService: ModalsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const cfgConfiguration: FieldGroup = this.fieldConfig?.additionalDataSource;

    // only move on if we have the configuration loaded
    if(!cfgConfiguration) {
      return;
    }

    if (this.fieldConfig.previewMode) {
      this.width = 70;
    }

    // Note configuration loading validators were moved to custom data list level, init CFG logic

    // TODO improve error handling since we're no longer doing data fetch here directly
    if(cfgConfiguration.error) {
      console.error(`Error loading CFG with ID ${cfgConfiguration.id}!`);
      console.error(cfgConfiguration.error)
      this.loadingError = cfgConfiguration.error;
      return;
    }

    this.formFragmentTitleKey = get(this.fieldConfig, 'displayOptions.formFragmentListLabelField', null);
    // flatten CFG field definitions and create the form group model for the CFG
    this.flatFieldDefinitions = flattenFieldGroupDefinitions(cfgConfiguration.fieldDefinitions);
    this.isExternalDataSource = this.formConstructorService.anyFieldIsExternalDataSource(this.flatFieldDefinitions);

    // ensure root level mappings are done
    if (this.fieldConfig.inputType !== 'multiple') {
      const listOfRootPropertyMappings = get(cfgConfiguration, 'propertyMapping.mapToRoot', null);
      this.bindControlsToBeMappedToRoot(listOfRootPropertyMappings);
    }

    this.customFieldGroup = cfgConfiguration

    this.infoTooltip = generateHelpTooltip(this.fieldConfig.description, this.readOnlyMode);
  }

  ngOnDestroy(): void {
    this.componentSubscriptions.unsubscribe();
  }

  /**
   * This method will make sure that if the root mapping is enabled for a field inside CFG with a given key
   * then changing the field value inside the CFG will result in that change being propagated to parent form
   * field that has the same key as the field in the CFG. I.e. this PUSHES the values from CFG into parent form
   *
   * `initControlsToBeMappedToRoot` ensures that field controls and initial values are set up
   *
   * @param listOfRootPropertyMappings list of keys for which mappings are done
   */
  private bindControlsToBeMappedToRoot(listOfRootPropertyMappings: string[]) {
    if (!listOfRootPropertyMappings) {
      return;
    }

    // form group in which the CFG is embedded
    const parentFormGroup = this.fieldControlGroup.parent as UntypedFormGroup;

    listOfRootPropertyMappings.forEach((keyToBeMapped) => {
      const cfgControlToMap = this.fieldControlGroup.get(keyToBeMapped);
      // bail if the specified key does not exist in the CFG
      if (!cfgControlToMap) {
        return;
      }
      // for every change on the CFG field, set that value on the parent form field with the same key
      this.componentSubscriptions.add(
        cfgControlToMap
          .valueChanges
          .pipe(debounceTime(100))
          .subscribe(value => parentFormGroup.get(keyToBeMapped).patchValue(value))
      );
    });
  }

  addFormGroupToArray(event) {
    // to prevent modals from closing if the group is opened in modal
    event.preventDefault();

    this.formConstructorService.pushCustomFieldGroupIntoFormArray(
      this.flatFieldDefinitions,
      this.formArray,
      { initialValues: null, editMode: false, contentLocaleId: this.activeLocale?.id || null }
    );
    this.formArray.markAsDirty();
  }

  removeFormGroupFromArray(index) {
    this.modalsService
      .confirm($localize`Delete Custom Field Group item`, $localize`Are you sure you want to delete Custom Field Group item?`)
      .pipe(filter((result) => !!result))
      .subscribe(() => {
        this.formArray.removeAt(index);
        this.formArray.markAsDirty();
      })
  }

  reorderFormGroup(event: CdkDragDrop<string[]>) {
    const formArray: any = this.fieldControlGroup;

    moveItemInArray(formArray.controls, event.previousIndex, event.currentIndex)
    this.fieldControlGroup.updateValueAndValidity();
    this.fieldControlGroup.markAsDirty();
  }

  // TODO Remove the methods moveItemInFormArray() and clamp() since we are now using the helper moveItemInArray()
  moveItemInFormArray(formArray, fromIndex, toIndex) {
    const from = this.clamp(fromIndex, formArray.length - 1);
    const to = this.clamp(toIndex, formArray.length - 1);

    if (from === to) {
      return;
    }

    const previous = formArray.at(from);
    const current = formArray.at(to);
    formArray.setControl(to, previous);
    formArray.setControl(from, current);
  }

  clamp(value, max) {
    return Math.max(0, Math.min(max, value));
  }

  hasCustomLabel() {
    const formFragmentListLabelField = get(this.fieldConfig, 'displayOptions.formFragmentListLabelField', null);
    return !!formFragmentListLabelField;
  }

  // this is for CFG fields of type multiple, i.e. dynamic form fragment lists
  getCustomLabel(group, groupIndex = 0) {
    const formFragmentListLabelField = get(this.fieldConfig, 'displayOptions.formFragmentListLabelField', null);

    // no custom fields in the array, bail out
    // note: this happens due to customFields having different values as the custom data form
    // gets reinitialized on article edit form on save
    if(!isIterable(this.customFields) ) {
      return '';
    }

    let customField;
    const labelFields = Array.from(this.customFields).filter(field => field.fieldConfig.key === formFragmentListLabelField);
    if (labelFields.length > groupIndex) {
      customField = labelFields[groupIndex];
    }
    const fieldType = customField && customField?.fieldConfig?.fieldType;
    if(fieldType && ['autocomplete', 'select'].includes(fieldType)) {
      const selection = customField.getSelectedValue();
      if(customField?.fieldConfig?.inputType == 'single') {
        return selection?.name || selection?.headline || selection?.label;
      }
      if(selection && customField?.fieldConfig?.inputType == 'multiple') {
        return selection.map(selected => {
          return selected?.name || selected?.headline || selected?.label;
        }).join(', ')
      }
    }
    if(fieldType && ['texteditor'].includes(fieldType)){
      const selection = customField.getSelectedValue();
      const helperElement = document.createElement("div");
      helperElement.innerHTML = selection;
      const selectionText = helperElement.textContent || helperElement.innerText || "";
      return selectionText;
    }
    return group.get(formFragmentListLabelField).value || '';
  }

  handlePanelExpansion(id) {
    if (!this.loadedPanelIds.includes(id)) {
      this.loadedPanelIds.push(id);
    }
  }

  isPanelLoaded(id) {
    return this.loadedPanelIds.includes(id);
  }
}

function isIterable(value) {
  // checks for null and undefined
  if (!value) {
    return false;
  }
  return typeof value[Symbol.iterator] === 'function';
}
